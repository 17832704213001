.PWAcontainer {
    width: 100vw;
    height: 80vh;
    text-align: center;
}

.PWAcontainer h1 {
    color: rgba(0, 99, 79, 1);
    font-family: 'Open Sans', sans-serif;
    font-size: 20pt;
    font-weight: 700;
    line-height: 1.75;
    text-align: center;
}

.PWAcontainer p {
    color: rgba(33, 33, 33, 1);
    font-family: 'Open Sans', sans-serif;
    font-size: 13pt;
    font-weight: 500;
    line-height: 1.75;
}

.PWAbuttonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}