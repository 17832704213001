.Guide {
    color: rgba(33, 33, 33, 1);
    font-family: 'Open Sans', sans-serif;
    font-size: 12pt;
    font-weight: 400;
    line-height: 1.75;
    margin-top: 15px;
}

.Guide a {
    color: inherit;
}