.RegelTopText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-color: white;
    border-radius: 0px;
    border-style: solid;
    border-width: 6px;
    padding-bottom: 20px;
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 20px;
    font-weight: 700;
    letter-spacing: -0.8px;
    line-height: 1.11;
    color: white;
    font-size: 35pt;
    font-family: 'Playfair Display', serif;
}

.RegelTopImg {
    width: 100%;
    height: 100%;
}

.RegelTop:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 99, 79, 0.3);
}

.RegelTop {
    width: 100vw;
    /*height: 30vh;*/
    position: relative;
    text-align: center;
}