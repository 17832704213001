.RegelTextCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-color: white;
    border-radius: 0px;
    border-style: solid;
    border-width: 6px;
    padding-bottom: 20px;
    padding-left: 80px;
    padding-right: 80px;
    padding-top: 20px;
    font-weight: 700;
    letter-spacing: -0.8px;
    line-height: 1.11;
    color: white;
    font-size: 35pt;
    font-family: 'Playfair Display', serif;
}

@media screen and (min-width: 480px) {
    .RegelTextCenter {
        font-size: 54pt;
        padding-bottom: 44px;
        padding-left: 56px;
        padding-right: 56px;
        padding-top: 44px;
    }
}

@media screen and (min-width: 480px) {
    .Regel * {
        margin-left: 15rem;
        margin-right: 15rem;
    }
}

.Regel {
    padding: 2rem;
}

.RegelEven {
    background-color: rgb(0, 0, 0, 0);
}

.RegelOdd {
    background-color: rgba(0, 79, 63, 1);
}

.RegelEven h2 {
    color: rgba(33, 33, 33, 1);
    font-family: 'Open Sans', sans-serif;
    font-size: 12pt;
    font-weight: 400;
    line-height: 1.75;
}

.RegelEven div * {
    color: rgba(33, 33, 33, 1);
    font-family: 'Open Sans', sans-serif;
    font-size: 12pt;
    font-weight: 400;
    line-height: 1.75;
}

.RegelOdd h2 {
    color: rgba(246, 246, 246, 1);
    font-family: 'Open Sans', sans-serif;
    font-size: 12pt;
    font-weight: 400;
    line-height: 1.75;
}

.RegelOdd div {
    color: rgba(246, 246, 246, 1);
    font-family: 'Open Sans', sans-serif;
    font-size: 12pt;
    font-weight: 400;
    line-height: 1.75;
}