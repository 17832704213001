.Elevkåren {
    text-align: center;
    margin: 1.5rem;
    margin-bottom: 6rem;
}

.Elevkåren p {
    color: rgba(33, 33, 33, 1);
    font-family: 'Open Sans', sans-serif;
    font-size: 13pt;
    font-weight: 500;
    line-height: 1.75;
}

.Elevkåren h3 {
    color: rgba(0, 99, 79, 1);
    font-family: 'Open Sans', sans-serif;
    font-size: 13pt;
    font-weight: 600;
    line-height: 1.75;
    text-align: center;
}

.Elevkåren h3 a {
    color: inherit;
}

.Elevkåren .OBS {
    color: rgba(0, 99, 79, 1);
    font-family: 'Playfair Display', serif;
    font-size: 29pt;
    font-weight: 400;
    letter-spacing: -0.6px;
    line-height: 1.44;
    text-align: center;
}