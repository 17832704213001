.KillerLogo {
    font-family: 'Playfair Display', serif;
    font-size: 18pt;
    font-weight: 700;
    letter-spacing: 0px;
    color: rgba(33, 33, 33, 1);
}

.navLinkOff {
    width: 100%;
    height: auto;
    background-color: white;
}

.navLinkOn {
    width: 100%;
    height: auto;
    background-color: rgba(0, 99, 79, 1);
}

.navLinkOn h3 {
    color: white;
    padding-left: 3rem;
    text-align: left;
    margin-bottom: 0rem;
    font-weight: 500;
    line-height: 2;
    font-family: 'Open Sans', sans-serif;
    font-size: 12pt;
}

.navLinkOff h3 {
    color: rgba(33, 33, 33, 0.85);
    padding-left: 3rem;
    text-align: left;
    margin-bottom: 0rem;
    font-weight: 400;
    line-height: 2;
    font-family: 'Open Sans', sans-serif;
    font-size: 11pt;
}

.Spin {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.SpinDefault {
    -webkit-transition-duration: 1s;
    -moz-transition-duration: 1s;
    -o-transition-duration: 1s;
    transition-duration: 1s;
    -webkit-transition-property: -webkit-transform;
    -moz-transition-property: -moz-transform;
    -o-transition-property: -o-transform;
    transition-property: transform;
}